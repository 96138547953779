import React from 'react';

function Home(){
    return (
        <section className='home'>
            <div>
                <h1>Wib-Project</h1>
                <h2>Une communauté numerique !</h2>
            </div>

            
        </section>
    );
}

export default Home;
