import React from 'react';

function CommingSoon(){
    return (
        <section className='commingSoon'>
            <h1>Wib-Project</h1>
            <h2>Notre site est en développement, merci de votre patience !</h2>
        </section>
    );
}

export default CommingSoon;
