import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "./pages/Home";
import CommingSoon from "./pages/CommingSoon";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<CommingSoon />} />

                <Route path='/wolfixisbeta' element={<Home />} />

                {/* ERROR 404 */}
                <Route path='*' element={<Home />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
